import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { encryptData, decryptData } from "../../utils/localEncruption";
import { decryptAPIData } from "../../utils/localEncruption";

// Define the base URLs for the APIs
const BASE_URL1 = `${process.env.REACT_APP_FD1_RATES_URL}`;
const BASE_URL2 = `${process.env.REACT_APP_FD2_RATES_URL}`;

// Helper function to fetch data from an API
const fetchDataFromAPI = async (url) => {
  const response = await fetch(url, { mode: "cors" });
  const encryptedData = await response.text();
  try {
    const decryptedData = await decryptAPIData(
      encryptedData,
      "gsDFQwTwuuOXWgh0JG/En4NtZWbahbacdQtlCkMg9tE="
    );
    return decryptedData;
  } catch (error) {
    console.error("Decryption failed for FD rate:", error);
  }

  return response.json();
};

// Helper function to get data from local storage
const getDataFromLocalStorage = (key) => {
  const storedData = localStorage.getItem(key);
  const salt = `${process.env.REACT_APP_SALT}`;

  if (!storedData) {
    return null;
  }

  try {
    const decryptedData = decryptData(storedData, salt);
    return decryptedData;
  } catch (error) {
    return null;
  }
};

// Helper function to save data to local storage
const saveDataToLocalStorage = (key, data) => {
  const salt = `${process.env.REACT_APP_SALT}`;
  const encryptedData = encryptData(data, salt);

  localStorage.setItem(key, encryptedData);
};

// Helper function to get the timestamp when data was last fetched
const getTimestampFromLocalStorage = (key) => {
  return localStorage.getItem(`${key}_timestamp`);
};

// Helper function to save the timestamp when data is fetched
const saveTimestampToLocalStorage = (key) => {
  const timestamp = new Date().getTime();
  localStorage.setItem(`${key}_timestamp`, timestamp);
};

// Define a threshold in milliseconds for data freshness (e.g., 1 hour)
const DATA_FRESHNESS_THRESHOLD = 60 * 60 * 1000; // 1 hour

// Async thunk to fetch data from both APIs
export const fetchFdRates = createAsyncThunk(
  "fdRateData/fetchFdRates",
  async (_, thunkAPI) => {
    let apiData1 = getDataFromLocalStorage("apiData1");
    let apiData2 = getDataFromLocalStorage("apiData2");
    const timestamp1 = getTimestampFromLocalStorage("apiData1");
    const timestamp2 = getTimestampFromLocalStorage("apiData2");
    const currentTime = new Date().getTime();

    // Check if data in local storage is stale (e.g., more than 1 hour old)
    const isData1Stale =
      !timestamp1 || currentTime - timestamp1 > DATA_FRESHNESS_THRESHOLD;
    const isData2Stale =
      !timestamp2 || currentTime - timestamp2 > DATA_FRESHNESS_THRESHOLD;

    // If any of the data is stale or missing, fetch from API
    if (!apiData1 || !apiData2 || isData1Stale || isData2Stale) {
      try {
        const [data1, data2] = await Promise.all([
          fetchDataFromAPI(BASE_URL1),
          fetchDataFromAPI(BASE_URL2),
        ]);

        // Save fetched data and timestamps to local storage
        saveDataToLocalStorage("apiData1", data1);
        saveDataToLocalStorage("apiData2", data2);
        saveTimestampToLocalStorage("apiData1");
        saveTimestampToLocalStorage("apiData2");

        return { apiData1: data1, apiData2: data2 };
      } catch (error) {
        // Handle API fetch errors gracefully
        return thunkAPI.rejectWithValue(error.message);
      }
    }

    return { apiData1, apiData2 };
  }
);

// Define the initial state with potential local storage data
const initialState = {
  isLoading: false,
  data1: getDataFromLocalStorage("apiData1") || {}, // Data from the first API
  data2: getDataFromLocalStorage("apiData2") || {}, // Data from the second API
  isError: false,
  selectedFilters: [],
  selectedTenor: "All terms",
  selectedBanks: [],
  sliderState: {
    year: 0,
    month: 0,
    sliderRange: 0,
    isActive: true,
  },
};

// Create slice with additional logic for local storage handling
const fdRateDataSlice = createSlice({
  name: "fdRateData",
  initialState,

  reducers: {
    toggleFilterFD: (state, action) => {
      const selectedCategory = action.payload;
      if (state.selectedFilters.includes(selectedCategory)) {
        state.selectedFilters = state.selectedFilters.filter(
          (element) => element !== selectedCategory
        );
      } else {
        state.selectedFilters.push(selectedCategory);
      }
    },
    resetFiltersFD: (state) => {
      state.selectedFilters = [];
    },

    setSelectedTenor: (state, action) => {
      state.selectedTenor = action.payload;
    },

    setSliderYear: (state, action) => {
      state.sliderState.year = action.payload;
    },

    setSliderMonth: (state, action) => {
      state.sliderState.month = action.payload;
    },

    setSliderRange: (state, action) => {
      state.sliderState.sliderRange = action.payload;
    },

    setIsActive: (state, action) => {
      state.sliderState.isActive = action.payload;
    },
    setSelectedBanks: (state, action) => {
      state.selectedBanks = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchFdRates.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchFdRates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data1 = action.payload.apiData1; // Populate state with data from the first API
      state.data2 = action.payload.apiData2; // Populate state with data from the second API
    });
    builder.addCase(fetchFdRates.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("Failed to fetch FD rates:", action.payload);
    });
  },
});

export const {
  toggleFilterFD,
  resetFiltersFD,
  setSelectedTenor,
  setSliderYear,
  setSliderMonth,
  setSliderRange,
  setIsActive,
  setSelectedBanks,
  setVisibleColumns,
} = fdRateDataSlice.actions;
export default fdRateDataSlice.reducer;
