const getDaysFromYearAndMonths = (months) => {
  const presentDate = new Date(); // 10th May, 2022
  const maturityDate = new Date();

  maturityDate.setMonth(presentDate.getMonth() + months);

  const presentDateInMs = presentDate.getTime();
  const maturityDateInMs = maturityDate.getTime();

  const differenceBtwDates = maturityDateInMs - presentDateInMs;
  const aDayInMs = 24 * 60 * 60 * 1000;

  const daysDiff = Math.round(differenceBtwDates / aDayInMs);
  return daysDiff;
  //
  //
};

const getYearsFromDays = (days) => {
  const years = days / 365.25;

  // Round the number of years down to the nearest integer.
  const yearCount = Math.round(years * 10) / 10;
  // Return the year count.
  return yearCount;
};

export { getDaysFromYearAndMonths, getYearsFromDays };
