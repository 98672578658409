import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import ChevronDownIcon from "../../components/icons/ChevronDownIcon.jsx";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import { setSelectedBanks } from "../../redux/slice/savingsFullRatesSlice";
import styles from "./searchBank.module.css";
import { SearchIcon } from "../../components/icons/SearchIcon.jsx";
import InputAdornment from "@mui/material/InputAdornment";

function SearchBank({ selectedBanks, setSelectedBanks, sortedItems, width }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1230);
  // const selectedBanks = useSelector(
  //   (state) => state.savingsFullRate.selectedBanks
  // );
  // const sortedItems = useSelector((state) => state.savingsFullRate.sortedItems);

  const handleChange = (event, newValue) => {
    dispatch(setSelectedBanks(newValue));
  };
  return (
    <>
      {" "}
      <Autocomplete
        // size="small"
        className={`${styles.bankSearchMobile}`}
        limitTags={isTablet ? 1 : 2}
        multiple
        sx={{
          "& .MuiOutlinedInput-root": {
            minHeight: "3.2rem",
            background: "#ffffff",
            // outline: "none",
            // border: "none",
            "@media (max-width: 991px)": {
              minHeight: width !== 768 ? "2.5rem" : "3.2rem", // Height for screens <= 600px
              fontSize: "1rem",
            },

            borderRadius: "1.625rem",
            padding: "0 0.5rem 0 0.5rem",
            fontSize: "1.2rem",
            boxShadow:
              "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1.625rem",
            // padding: " 0 3.5rem 0 1.5rem",
            // fontSize: "1.2rem",
            border: "1px solid #ccc",
            transition: "border-color 0.3s ease",

            // boxShadow:
            //   "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          },
        }}
        disableCloseOnSelect
        id="tags-standard"
        options={sortedItems}
        getOptionLabel={(option) => option.name}
        value={selectedBanks}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            (option) =>
              option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.bref.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.acronym1
                ?.toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              option.acronym2?.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option.mobile_name}
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            // variant="filled"
            // label="Size small"
            placeholder={selectedBanks.length === 0 ? "Search Banks..." : ""}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon className={styles.searchLogo}></SearchIcon>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        style={{
          width: "100%",
          //   marginLeft: isTablet ? "10px" : "0px",
        }}
      />
    </>
  );
}

export default SearchBank;
