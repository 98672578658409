import React, { useEffect } from "react";
import { useState } from "react";
// import fdrateData from "./fdrate";
import LoadingIndicator from "../components/LoadingIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from "react-ga4";
import styles from "./fdPage.module.css";
import FdTable from "./fdTable/FdTable";
import Slider from "./slider/Slider";
import { fetchFdRates } from "../redux/slice/fdRateDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { getCurrentMonthAndYear } from "../utils/lastUpdateDate";
import Spinner from "../components/Spinners/Spinner.jsx";

function FDPage() {
  const dispatch = useDispatch();
  const { isLoading, data1, data2, isError } = useSelector(
    (state) => state.fdRateData
  );
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "  FD Page",
    });
  }, []);
  useEffect(() => {
    // Check if data is already available or needs fetching
    if (!Object.keys(data1).length || !Object.keys(data2).length) {
      dispatch(fetchFdRates());
    }
  }, [dispatch, data1, data2]);

  if (isLoading)
    return (
      <div className={styles.spinner}>
        {" "}
        <Spinner sx={{ mb: "5px" }} />
      </div>
    );
  // if (isError) return <p>Error loading data. Please try again later.</p>;
  if (!Object.keys(data1).length || !Object.keys(data2).length)
    return <p>No data available.</p>;
  const { month, year } = getCurrentMonthAndYear();

  return (
    <div className={styles.container}>
      {/* <Slider /> */}
      <div className={styles.homeRow}>
        <div className={styles.textContent}>
          <div className={styles.textWrapper}>
            <Typography variant="h1" gutterBottom className={styles.heading}>
              Best FD Rates for {month}, {year}
              <span className={styles.subHeading}></span>
            </Typography>
            {/* <h4 class={styles.blackish}>
                  We offer special services for special people!
                </h4> */}
          </div>
        </div>
        {/* <div className={styles.banner}>
            <img
              className={styles.mainImage}
              src="https://cdni.iconscout.com/illustration/premium/thumb/businessman-working-using-vr-tech-3840669-3202986.png?f=webp"
              alt="savings banner"
            />
          </div> */}
      </div>

      <FdTable />
    </div>
  );
}

export default FDPage;
