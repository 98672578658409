import React, { useState } from "react";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerContent}>
        <Link
          className={styles.footerLink}
          to="/contactUs"
          // target="_blank"
        >
          Contact Us
        </Link>
        <Link
          className={styles.footerLink}
          to="/privacy"
          // target="_blank"
        >
          Privacy
        </Link>
        <Link
          className={styles.footerLink}
          to="/TandC"
          // target="_blank"
        >
          {isMobile ? "T&C" : "Terms & Conditions"}
        </Link>

        {/* <Link
            className={styles.footerLink}
            href="./#0"
            jsaction="click:LHQi1b"
          >
            Feedback
          </Link> */}
        <Link
          className={styles.footerLink}
          to="/content"
          // target="_blank"
        >
          Learning Centre
        </Link>
        <div className={styles.socialIcons}>
          <Link to="https://www.facebook.com/" target="_blank">
            <FacebookIcon sx={{ mr: "10px" }} />
          </Link>
          <Link to="https://x.com" target="_blank">
            <XIcon sx={{ mr: "10px" }} />
          </Link>
          <Link to="https://in.linkedin.com/" target="_blank">
            <LinkedInIcon sx={{ mr: "10px", fontSize: "26px" }} />
          </Link>
          <Link to="https://www.instagram.com/" target="_blank">
            <InstagramIcon />
          </Link>
        </div>
      </div>
      <div className={styles.footerTextContainer}>
        <p className={styles.footerText}>
          Copyright © 2024, All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
