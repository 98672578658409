import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./bankRateDetails.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { lastUpdateDate } from "../../utils/lastUpdateDate";
import { useDispatch, useSelector } from "react-redux";
import { fetchSavings } from "../../redux/slice/savingsFullRatesSlice";
import { getSavingsFromLocalStorage } from "../../redux/slice/savingsFullRatesSlice";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";

function BankRateDetails() {
  const { id } = useParams();
  //
  const savingsDetails = useSelector((state) => state.savingsFullRate.data[id]);

  const [filteredItems, setFilteredItems] = useState(savingsDetails?.ratings);
  const { isError, isLoading } = useSelector((state) => state.savingsFullRate);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [maxRate, setMaxRate] = useState(0);

  useEffect(() => {
    if (savingsDetails?.ratings) {
      const updatedRatings = savingsDetails.ratings.map((rating, index) => ({
        ...rating,
        order: index + 1, // Add the order property
        tenor: (() => {
          const previousAmount = index > 0 ? filteredItems[index - 1] : null;
          const tenor =
            index === 0
              ? `0 to ${formatNumber(rating.amount)}`
              : `Above ${formatNumber(previousAmount.amount)}   ${
                  rating.amount !== "$"
                    ? "to " + formatNumber(rating.amount)
                    : ""
                }`;
          return tenor;
        })(),
      }));
      setFilteredItems(updatedRatings);
    }
  }, []);

  const findMaxRate = () => {
    let rate = 0;
    savingsDetails.ratings.map((item, index) => {
      if (item.ratings > rate) {
        rate = item.ratings;
      }
    });
    setMaxRate(rate);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data if savingsDetails[id] is not available or if it's empty
    if (!savingsDetails) {
      dispatch(fetchSavings());
    }
    findMaxRate();
  }, [dispatch, id, savingsDetails]);

  const numberFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    notation: "compact",
    compactDisplay: "long",
  });

  const columns = [
    {
      name: "Deposit Slabs",
      accessor: "order",
      sortable: true,
      def: true,
      classColumn: "tenor",
    },
    {
      name: "Interest Rate",
      accessor: "ratings",
      sortable: true,
      def: true,
      classColumn: "interestRate",
    },
  ];

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    //
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);

    //  if(accessor === "amount"){
    //    return null;
    //  }
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        const isNumericA =
          !isNaN(parseFloat(a[sortField])) && isFinite(a[sortField]);
        const isNumericB =
          !isNaN(parseFloat(b[sortField])) && isFinite(b[sortField]);

        // If both values are numeric, perform numeric comparison
        if (isNumericA && isNumericB) {
          const numA = parseFloat(a[sortField]);
          const numB = parseFloat(b[sortField]);
          return (numA - numB) * (sortOrder === "asc" ? 1 : -1);
        }

        // Otherwise, perform string comparison
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });

      setFilteredItems(sorted);

      //
    }
  };

  const formatNumber = (number) => {
    const formattedNumber = numberFormatter.format(number);

    // Replace "T" with "thousand"
    const customFormattedNumber = formattedNumber.replace("T", "000");

    return customFormattedNumber;
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading data</p>;
  if (!savingsDetails) return null;
  return (
    <>
      <div className={styles.bankListContainer4}>
        <div className={styles.bankListWrapper}>
          <div className={styles.card}>
            <div className={styles.img}>
              <img
                width="50x"
                height="50px"
                src={`../bankLogos/${
                  savingsDetails && savingsDetails.bcode
                }.png`}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <div className={styles.textBox}>
              <div className={styles.textContent}>
                <p className={styles.h1}>
                  {savingsDetails.mobile_name}
                  {isMobile ? " Savings Rates" : " Savings Interest Rates"}
                </p>
                {/* <span className={styles.span}>12 min ago</span> */}
              </div>
              <p className={styles.p}>
                {isMobile ? (
                  <span>
                    This{" "}
                    {savingsDetails.name === "Post Office"
                      ? "institution"
                      : "bank"}{" "}
                    last updated its rates on: {savingsDetails.effdate}.
                  </span>
                ) : (
                  <span>
                    This{" "}
                    {savingsDetails.name === "Post Office"
                      ? "institution"
                      : "bank"}{" "}
                    last updated its savings rates on: {savingsDetails.effdate}.
                  </span>
                )}
              </p>
              <div></div>
            </div>
          </div>
          <div className={styles.tableWraper4}>
            <table className={styles.responsiveTable4}>
              <thead>
                <tr>
                  {columns.map(
                    ({ name, accessor, sortable, def, classColumn }, index) => {
                      const cl = sortable
                        ? sortField === accessor && order === "asc"
                          ? "Up"
                          : sortField === accessor && order === "desc"
                          ? "Down"
                          : "default"
                        : "";

                      return (
                        <th
                          key={accessor}
                          onClick={() => handleSortingChange(accessor)}
                          className={styles[classColumn]}
                        >
                          <div className={styles.sortDiv}>
                            <span className={styles.bankHeading}> {name}</span>

                            <span>
                              <FontAwesomeIcon
                                icon={
                                  cl === "Up"
                                    ? faSortUp
                                    : cl === "Down"
                                    ? faSortDown
                                    : faSort
                                }
                                style={{
                                  color:
                                    cl === "default" ? "#4a5568" : "#0062E3",
                                }}
                                className={styles.arrow}
                              />
                              {/*                                     
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      style={{ color: "#4a5568" }}
                                      className={styles.arrow}
                                    /> */}
                            </span>
                          </div>
                        </th>
                      );
                    }
                  )}
                </tr>
              </thead>
              <tbody>
                {/* Mapping through bankData array to render rows */}
                {filteredItems &&
                  filteredItems.map((row, index) => {
                    return row.amount !== null && row.amount !== "x" ? (
                      <tr key={index}>
                        <td className={styles.bankName4}>{row.tenor}</td>
                        <td className={styles.bankName4}>
                          {Number(row.ratings).toFixed(2)}%
                          {row.ratings === maxRate ? (
                            <Chip
                              label="Highest"
                              size="small"
                              sx={{
                                ml: "10px",
                                color: "#FFFFFF",
                                fontSize: "11px",
                                height: "20px",
                                borderRadius: "5px",
                                style: "none",
                                backgroundColor: "#ad1e23",
                              }}
                            ></Chip>
                          ) : null}
                        </td>
                      </tr>
                    ) : null;
                  })}
              </tbody>
            </table>
            <div className={styles.features}>
              {/* <h4>Features and Benefits of {fdData[0].name} FD</h4> */}
              <div className={styles.featuresList}>
                <ul>
                  <li aria-level="1">
                    <span>
                      1. Data source: Official{" "}
                      {savingsDetails.name === "Post Office"
                        ? "institution"
                        : "bank"}{" "}
                      <a
                        target="_blank"
                        href={savingsDetails.url}
                        style={{ color: "#0062E3" }}
                      >
                        {" "}
                        website.
                      </a>
                      {/* {savingsDetails.max_fd_limit !== "no"
                        ? ` Rates applicable for callable deposits ${"< "}${
                            savingsDetails.max_fd_limit
                          }`
                        : " Rates applicable for callable deposits with no maximum deposit limit."} */}
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>
                      {savingsDetails.type === "NBFC"
                        ? "2. NBFC deposits are not covered by DICGC insurance of Rs 5 lakhs."
                        : savingsDetails.type === "Post Office"
                        ? "2. Post Office deposits don't have DICGC insurance but are backed by the Government of India under the Government Savings Promostion Act, 1873. "
                        : "2. This bank is covered by DICGC insurance up to ₹5 lakhs, including principal and interest amounts."}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankRateDetails;
