import { configureStore, combineReducers } from "@reduxjs/toolkit";
import savingsFullRatesReducer from "./slice/savingsFullRatesSlice"; // Import the existing reducer
import fdRateDataReducer from "./slice/fdRateDataSlice"; // Import the new reducer

// Combine the reducers
const rootReducer = combineReducers({
    savingsFullRate: savingsFullRatesReducer,
    fdRateData: fdRateDataReducer,
});

// Configure the store
const store = configureStore({
    reducer: rootReducer,
});

export default store;
