import React, { useEffect, useState } from "react";
// import styles from "./filters.module.css";
import styles from "./mobileFilters.module.css";
import { findBestFdRate } from "../../utils/data";
import fdData2 from "../../utils/data";
import Slider from "@mui/material/Slider";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import {
  setSelectedTenor,
  setIsActive,
} from "../../redux/slice/fdRateDataSlice.js";

function Filters({
  filters,
  handleFilterButtonClick,
  // majorHandler,
  allHandler,
  isAllChecked,
  isMajorChecked,
  selectedFilters,
  clearHandler,
  majorLength,
  // findBestFdRate,
  BanksCounts,
  sliderRange,
  setSliderRange,
  year,
  month,
  setYear,
  setMonth,
  selectedTenor,
  setTenorData,
  setFilterIsClicked,
  filterIsClicked,
}) {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const handleBackButton = (event) => {
  //     if (event.state) {
  //       navigate('/FDpage'); // Redirect to page1 when back button is pressed
  //     }
  //   };

  //   window.addEventListener('popstate', handleBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', handleBackButton);
  //   };
  // }, [navigate]);
  // const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const isActive = useSelector(
    (state) => state.fdRateData.sliderState.isActive
  );
  const dispatch = useDispatch();
  //

  const handleTopFdRatesDataChange = (event) => {
    //
    dispatch(setSelectedTenor(event.target.value));
    dispatch(setMonth(0));
    dispatch(setYear(0));
    dispatch(setSliderRange(0));
    dispatch(setIsActive(true));
    setHelperText("");
    setError(false);
  };
  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    // setTenor("");
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    setHelperText("");
    setError(false);
    const selectedYear = Math.floor(value / 12);
    const selectedMonth = value % 12;

    dispatch(setYear(selectedYear));
    dispatch(setMonth(selectedMonth));
    dispatch(setSliderRange(value));
  };

  const handleYearChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    //
    if (Number.isInteger(Number(value))) {
      if (Number(value) > 5) {
        dispatch(setYear(5));
        dispatch(setSliderRange(5 * 12 + month));
        setError(true);
        setHelperText(" Year Value must be less than 6");
      } else {
        dispatch(setYear(value));
        const sliderValue = parseInt(value) * 12 + month;
        dispatch(setSliderRange(sliderValue));
        setError(false);
        setHelperText("");
      }
    } else {
      setHelperText(" Year Value must be less than 6");
      setError(true);
    }
  };

  const handleMonthChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    if (!isNaN(value)) {
      if (Number(value) > 11) {
        dispatch(setMonth(11));
        const sliderValue = year * 12 + 11;
        dispatch(setSliderRange(sliderValue));
        setHelperText("Month Value must be less than 12");
        setError(true);
      } else {
        dispatch(setMonth(value));
        const sliderValue = year * 12 + parseInt(value);
        dispatch(setSliderRange(sliderValue));
        setHelperText("");
        setError(false);
      }
    } else {
      setHelperText("Month Value must be less than 12");
      setError(true);
    }
  };

  const isActiveHandler = () => {
    dispatch(setIsActive(false));
    dispatch(setSelectedTenor(""));
    dispatch(setSliderRange(12));
    dispatch(setYear(1));
  };

  const isActiveAllterms = () => {
    dispatch(setSelectedTenor("All terms"));
    dispatch(setIsActive(true));
    dispatch(setSliderRange(0));
    dispatch(setYear(0));
    dispatch(setMonth(0));
  };

  return (
    <>
      <div className={styles.authorsWrapFD}>
        <div className={styles.filterHeaderConMain}>
          <div className={styles.filterHeaderSubCon}>
            <div className={styles.filterButtonCon}>
              <button
                className={styles.filterButton}
                onClick={() => setFilterIsClicked(false)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  focusable="false"
                  className={styles.filterSVG}
                >
                  <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                </svg>
              </button>
            </div>
            <h1 className={styles.filterH1}>Filters</h1>
          </div>
        </div>
        <ul className={styles.filtersFD}>
          <li>
            <div className={styles.filterHeadingFD}>
              <span>Bank Type </span>
            </div>
            <fieldset className={styles.BankType}>
              <div className={styles.bankTypeRows1}>
                <input
                  className={styles.checkBoxFD}
                  type="checkbox"
                  name="acs"
                  // defaultChecked="true"
                  value="Germany"
                  id="Major"
                  checked={isMajorChecked}
                  onChange={allHandler}
                />
                <label htmlFor="Major">
                  Major {BanksCounts.majorLength} Banks
                </label>
              </div>
              <div className={styles.bankTypeRows1}>
                <input
                  className={styles.checkBoxFD}
                  type="checkbox"
                  name="acs"
                  checked={isAllChecked}
                  id="All"
                  onChange={allHandler}
                />
                <label htmlFor="All">
                  All Banks{" "}
                  <small className={styles.banksCount}>
                    ({BanksCounts["All Banks"]})
                  </small>
                </label>
                <span className={styles.clearFD} onClick={clearHandler}>
                  clear
                </span>
              </div>
              {filters.map((category, idx) => (
                <div className={styles.filtersDivFD} key={idx}>
                  <input
                    type="checkbox"
                    // name="acs"
                    // value="Germany"
                    checked={selectedFilters.includes(category.filterName)}
                    id={idx}
                    onChange={() =>
                      handleFilterButtonClick(category.filterName)
                    } // only call method by using arrow syntax else it is called at every render and not on click event
                    key={`filters ${idx}`}
                  />
                  <label htmlFor={idx} className={styles.filterLabelFD}>
                    {category.filterName}{" "}
                    <small className={styles.banksCountFD}>
                      ({BanksCounts[`${category.filterName}`]})
                    </small>
                  </label>
                </div>
              ))}
            </fieldset>
          </li>
          <li>
            <div className={styles.filterHeadingFD1}>
              <span>Show the best rate for: </span>
            </div>
            <div
              className={!isActive ? styles.disableDiv : styles.enableDiv}
              onClick={selectedTenor === "" ? isActiveAllterms : null}
            >
              <span className={styles.subFilterHeading}>A given period:</span>
              <fieldset className={styles.BankType}>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    checked={selectedTenor === "All terms"}
                    defaultChecked={true}
                    value="All terms"
                    id="All terms"
                    onChange={handleTopFdRatesDataChange}
                  />
                  <label htmlFor="All terms">All terms</label>
                </div>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    // defaultChecked="true"
                    value="0 - 1y"
                    id="0 - 1y"
                    onChange={handleTopFdRatesDataChange}
                    checked={selectedTenor === "0 - 1y"}
                  />
                  <label htmlFor="0 - 1y">
                    0<small className={styles.banksCountFD}></small> to 1
                    <small className={styles.banksCountFD}> year</small>{" "}
                  </label>
                </div>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    // defaultChecked="true"
                    value="1 - 2y"
                    id="1 - 2y"
                    checked={selectedTenor === "1 - 2y"}
                    onChange={handleTopFdRatesDataChange}
                  />
                  <label htmlFor="1 - 2y">
                    1<small className={styles.banksCountFD}></small> to 2
                    <small className={styles.banksCountFD}> years</small>
                  </label>
                </div>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    // defaultChecked="true"
                    value="2 - 3y"
                    id="2 - 3y"
                    checked={selectedTenor === "2 - 3y"}
                    onChange={handleTopFdRatesDataChange}
                  />
                  <label htmlFor="2 - 3y">
                    2<small className={styles.banksCountFD}></small> to 3
                    <small className={styles.banksCountFD}> years</small>
                  </label>
                </div>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    // defaultChecked="true"
                    value="3 - 5y"
                    id="3 - 5y"
                    checked={selectedTenor === "3 - 5y"}
                    onChange={handleTopFdRatesDataChange}
                  />
                  <label htmlFor="3 - 5y">
                    3<small className={styles.banksCountFD}></small> to 5
                    <small className={styles.banksCountFD}> years</small>
                  </label>
                </div>
                <div className={styles.bankTypeRows}>
                  <input
                    className={styles.checkBoxFD}
                    type="radio"
                    // name="top"
                    // defaultChecked="true"
                    value="Above 5y"
                    id="Above 5y"
                    checked={selectedTenor === "Above 5y"}
                    onChange={handleTopFdRatesDataChange}
                  />
                  <label htmlFor="Above 5y">
                    Above 5<small className={styles.banksCountFD}> years</small>{" "}
                  </label>
                </div>
              </fieldset>
            </div>
          </li>
          <li>
            <div className={styles.customDivider}>OR</div>
            <div
              className={isActive ? styles.disableDiv : styles.enableDiv}
              onClick={selectedTenor !== "" ? isActiveHandler : null}
            >
              <span className={styles.subFilterHeading}>A custom tenor: </span>
              <fieldset className={styles.BankType}>
                <div className={styles.RangeTenor}>
                  <div className={styles.yearAndMonthWrp}>
                    <div className={styles.rangeTenorYM}>
                      year{" "}
                      <input
                        className={
                          !isActive
                            ? styles.MonthAndYear
                            : styles.MonthAndYearDis
                        }
                        type="text"
                        value={year}
                        style={{ width: "50px", height: "25px" }}
                        onChange={handleYearChange}
                      />
                    </div>
                    {/* Display year error */}
                    <div className={styles.rangeTenorYM}>
                      {"  "}month{" "}
                      <input
                        className={
                          !isActive
                            ? styles.MonthAndYear
                            : styles.MonthAndYearDis
                        }
                        type="text"
                        value={month}
                        style={{ width: "50px", height: "25px" }}
                        onChange={handleMonthChange}
                      />
                    </div>
                  </div>
                  {/* Display month error */}
                  <FormHelperText sx={{ color: "red", ml: "5px" }}>
                    {helperText}
                  </FormHelperText>
                  <div className={styles.InputRangeSlider}>
                    <Slider
                      disabled={selectedTenor !== ""}
                      aria-label="Default"
                      // valueLabelDisplay="auto"
                      value={sliderRange}
                      min={0}
                      max={60}
                      onChange={handleSliderChange}
                      step={1}
                      id="sliderInput"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </li>
          <li>
            <div>
              <button
                type="submit"
                onClick={() => setFilterIsClicked(false)}
                className={styles.applyFiltersFD}
              >
                {" "}
                Apply Filters{" "}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Filters;
