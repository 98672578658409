import ReactGA from "react-ga4";

const InitializeGoogleAnalytics = () => {
  ReactGA.initialize("G-YT5F1J3X1Z");
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
