import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar/SearchBar";
import styles from "./savingsPage.module.css";
import ReactGA from "react-ga4";
import { Typography } from "@mui/material";
import { getCurrentMonthAndYear } from "../utils/lastUpdateDate";
import BankListing from "./BankListing/BankListing";
import SearchBank from "./SearchBank/SearchBank";

function SavingsPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "  Savings Page",
    });
  }, []);

  const { month, year } = getCurrentMonthAndYear();
  function getShortMonthName(monthName) {
    const date = new Date(`${monthName} 1, 2000`);
    return date.toLocaleString("en-US", { month: "short" });
  }

  return (
    <>
      {/* <section className={styles.one}> */}
      <div class={styles.homeContainer}>
        <div className={styles.homeRow}>
          <div className={styles.textContent}>
            <div className={styles.textWrapper}>
              <div>
                <Typography variant="h1" className={styles.heading}>
                  Best Savings Rates for {getShortMonthName(month)}. {year}
                  {/* <span className={styles.subHeading}></span> */}
                </Typography>
              </div>
              {/* <h4 class={styles.blackish}>
                We offer special services for special people!
              </h4> */}
            </div>
          </div>
        </div>
        {/* </section> */}
        {/* <div className={styles.searchContianer}>
          <SearchBank />
          <SearchBar />
        </div> */}
        <BankListing />
      </div>
    </>
  );
}

export default React.memo(SavingsPage);
