import React from "react";
import styles from "./spinner.module.css";

function Spinner() {
  return (
    /* From Uiverse.io by Donewenfu */
    <div class={styles.loader}>
      <div
        className={`${styles.justify_content_center} ${styles.jimu_primary_loading}`}
      ></div>
    </div>
  );
}

export default Spinner;
