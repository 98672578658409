import { useEffect } from "react";
import SavingsPage from "./SavingsPage/SavingsPage";
import Navbar from "./components/Navbar/Navbar.jsx";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import FDPage from "./FDPage/FDPage";
import Footer from "./components/footer/Footer.jsx";
import "./App.css";
import ContactUs from "./pages/ContactUsPage.jsx/ContactUs.jsx";
import ReactGA from "react-ga4";
import { InitializeGoogleAnalytics } from "./utils/googleAnalytics/googleAnalytics.js";
// import Test from "./test/Test.jsx";
import BankDetails from "./FDPage/bankDetails/BankDetails.jsx";
import Content from "./pages/Content/Content.jsx";
import BankRateDetails from "./SavingsPage/bankDetailsSavings/BankRateDetails.jsx";
import { useDispatch } from "react-redux";
import { fetchSavings } from "./redux/slice/savingsFullRatesSlice.js";
import { fetchFdRates } from "./redux/slice/fdRateDataSlice.js";
import Privacy from "./pages/privacy/Privacy.jsx";
import TermsOfService from "./pages/T&C/TermsOfService";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs.jsx";
import BlogSinglePost from "./components/blog/BlogSinglePost.jsx";
import BlogLanding from "./components/blog/BlogLanding.jsx";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchSavings());
    dispatch(fetchFdRates());
  }, [dispatch]);

  useEffect(() => {
    InitializeGoogleAnalytics();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home Page",
    });
  }, []);

  const giveColor = () => {
    if (
      location.pathname === "/FDpage" ||
      location.pathname === "/savingsPage"
    ) {
      return "#f8f9fa";
    } else if (location.pathname === "/contactUs") {
      return "#f1f3f4";
    } else {
      return "#ffffff";
    }
  };

  return (
    <div className="app">
      <Navbar />
      {location.pathname !== "/" && <Breadcrumbs color={giveColor()} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="FDpage" element={<FDPage />} />
        <Route path="savingsPage" element={<SavingsPage />} />
        <Route path="contactUs" element={<ContactUs />} />
        {/* <Route path="test" element={<BlogLanding />} /> */}
        <Route path="/bankDetails/:id" element={<BankDetails />} />
        <Route path="/savingsRateDetails/:id" element={<BankRateDetails />} />
        <Route path="/content" element={<Content />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/TandC" element={<TermsOfService />} />
        {/* <Route path="*" element={<PageNotFound />} /> */}
        {/* <Route path="/blog" element={<BlogLanding />} />
        <Route path="/blog/:id" element={<BlogSinglePost />} /> */}
      </Routes>

      {/* <div className="footer"> */}
      <Footer />
      {/* </div> */}

      {/* <Test /> */}
    </div>
  );
}

export default App;
