import React from 'react';
import styles from './privacy.module.css';
import { Typography } from '@mui/material';


const Privacy = () => {
    return (
        <div className={styles.container}>
            <Typography variant='h4' gutterBottom>Privacy Policy</Typography>
            <Typography variant='h6' gutterBottom >This Privacy Policy applies to Wanij.com</Typography>
            <p >www.wanij.com recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on www.wanij.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website you agree to this Privacy Policy.</p>
            <p >Wanij.com is a property of Sanskar Udhyog, an Indian Company registered under the Companies Act 2013 having its office at Maloo near Velocity Cinema, Ring Road, Indore-452010, India.</p>
            
            <Typography variant='h6' gutterBottom>Information we collect</Typography>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>Contact information.</Typography> We might collect your name, email, mobile number, phone number, street, city, state, pin code, country, and IP address.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>Payment and billing information.</Typography> We might collect your billing name, billing address and payment method when you transact from the website. We NEVER collect your credit card number or credit card expiry date or other details pertaining to your credit card on our website. Credit card information will be obtained and processed by our online payment partner.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>Information you post.</Typography> We collect information you post in a public space on our website or on a third-party social media site belonging to Wanij.com.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>Demographic information.</Typography> We may collect demographic information about you, banks you prefer to transact with or any other information provided by you during the use of our website. We might collect this as a part of a survey also.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>Other information.</Typography> If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using or the version of the operating system your computer or device is running.</p>
            
            <Typography variant='h6' gutterBottom>We collect information in different ways.</Typography>         
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We collect information directly from you.</Typography> We collect information directly from you when you register on our website. We also collect information if you post a comment on our website or ask us a question through phone, Contact Us form or email.</p>
            <p >  <Typography variant='span' sx={{fontWeight:'bold'}}>We collect information from you passively.</Typography> We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.</p>
            <p >  <Typography variant='span' sx={{fontWeight:'bold'}}>We get information about you from third parties.</Typography> If you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.</p>
            
            <Typography variant='h6' gutterBottom>Use of your personal information</Typography>  
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information to contact you:</Typography>  We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information to respond to your requests or questions.</Typography> We might use your information to confirm your registration for an event or contest.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information to improve our products and services.</Typography> We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information to look at site trends and customer interests.</Typography> We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information for security purposes.</Typography> We may use information to protect our company, our customers, or our websites.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information for marketing purposes.</Typography> We might send you information about special deals or offers. We might also tell you about new features or products. These might be our own promotional offers or products, or third-party offers or products we think you might find interesting.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We use information to send you transactional communications.</Typography> We might send you emails or SMS about your account or transaction on our website.</p>
            <p >We use information as otherwise permitted by law.</p>
            
        
            <Typography variant='h6' gutterBottom>Sharing of information with third-parties</Typography>       
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We will share information with third parties who perform services on our behalf.</Typography> We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We will share information with the banks or NBFCs.</Typography>  We share your information with banks or NBFC partners responsible for fulfilling the transactional obligation. These aforesaid partners/parties may use the information we give them as described in their privacy policies.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We may share information if we think we have to in order to comply with the law or to protect ourselves.</Typography> We will share information to respond to a court order or writ petition. We may also share it if a government agency, an authorized industry association, or investigatory body requests. Or we might also share information when we are investigating potential fraud.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We may share information with any successor to all or part of our business.</Typography> For example, if part of our business is sold, we may give our customer list as part of that transaction.</p>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>We may share your information for reasons not described in this policy.</Typography> We will tell you before we do this.</p>
            
           
            <Typography variant='h6' gutterBottom>Email Opt-Out</Typography>
            <p ><Typography variant='span' sx={{fontWeight:'bold'}}>You can opt out of receiving our marketing emails.</Typography> To stop receiving our promotional emails, please email to <a style={{ color: "#1a73e8" }} href="mailto:hi@wanij.com">hi@wanij.com</a>. It may take about fifteen business days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your transactions.</p>
            
            <Typography variant='h6' gutterBottom>Third party sites</Typography>
            <p >If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy policies of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third-party sites.</p>
            
            <Typography variant='h6' gutterBottom>Updates to this policy</Typography>
            <p >This Privacy Policy was last updated on 28-July-2024. From time to time we may change our privacy policy inclusions. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</p>
            
            <Typography variant='h6' gutterBottom>Jurisdiction</Typography>
            <p >If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>
        </div>
    );
}

export default Privacy;