import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import styles from "./T&C.module.css"
import { Link } from "react-router-dom";

const TermsOfService = () => {

  return (
    <div className={styles.container}>
      
      <Typography variant="h5" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" gutterBottom style={{marginTop: '15px'}}>
      Sanskar Udhyog <strong>(“Company”)</strong> operates the website www.wanij.com <strong>(“Site”)</strong>.
      </Typography>
      <Typography variant='body1' >
This page contains important information regarding the terms and conditions which apply when you
access our website. Please note that the information contained herein is subject to change without
notice.
</Typography>
      <Box sx={{mt:'15px'}} >
        <Typography variant="h6">1. Terms</Typography>
        <Typography variant="body1">
          By accessing the website at <Link to='https://www.wanij.com/'  target='_blank'  style={{color:'#1a73e8'}}>www.wanij.com</Link> you are agreeing to be bound by these terms of service all applicable laws and regulations and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
        </Typography>
      </Box>
      <Box sx={{mt:"15px"}}>
        <Typography variant="h6" gutterBottom>2. Use License</Typography>
        <Typography variant="body1" gutterBottom sx={{mb:'15px'}}>
          Permission is granted to temporarily download one copy of the materials (information only) on the Site for personal non-commercial transitory viewing only. This is the grant of a license not a transfer of title and under this license you may not:
          </Typography>
          <Typography variant="body1">
          <ol style={{marginLeft:'40px', marginBottom:'15px'}}>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose or for any public display (commercial or non-commercial);</li>
            <li>attempt to decompile or reverse engineer any software contained on Wanij’s website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server.</li>
          </ol>
          This license shall automatically terminate if you violate any of these restrictions and may be terminated by the company at any time. Upon terminating your viewing of these materials or upon the termination of this license you must destroy any downloaded materials in your possession whether in electronic or printed form.
        </Typography>
      </Box>
      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>3. Disclaimer</Typography>
         <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
         The information contained in this website is for general information purposes only. While we endeavour
to keep the information up to date and correct, we make no representations or warranties of any kind,
express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to
the website or the information, products, services, or related graphics contained on the website for any
purpose. Any reliance you place on such information is therefore strictly at your own risk.
         </Typography>
         <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
         In no event will we be liable for any loss or damage including without limitation, indirect or
consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits
arising out of, or in connection with, the use of this website.
         </Typography>
         <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
         Through this website you are able to link to other websites which are not under the control of the
Company. We have no control over the nature, content and availability of those sites. The inclusion of
any links does not necessarily imply a recommendation or endorse the views expressed within them.
         </Typography>
         <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
         Every effort is made to keep the website up and running smoothly. However, the company takes no
responsibility for, and will not be liable for, the website being temporarily unavailable due to technical
issues beyond our control.
         </Typography>
      </Box>
      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>4. Limitations</Typography>
      <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
      In no event shall the company or its suppliers be liable for any damages (including, without limitation,
damages for loss of data or profit, or due to business interruption) arising out of the use or inability to
use the materials on the Site, even if the company or company’s authorized representative has been
notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow
limitations on implied warranties, or limitations of liability for consequential or incidental damages,
these limitations may not apply to you.
         </Typography>
      </Box>

      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>5. Accuracy of materials</Typography>
      <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
      The materials appearing on the site could include technical, typographical, or photographic errors. Wanij
does not warrant that any of the materials on its website are accurate, complete or current. Wanij may
make changes to the materials contained on its website at any time without notice. However, Wanij
does not make any commitment to update the materials.
         </Typography>
      </Box>

      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>6. Links</Typography>
      <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
      Wanij has not reviewed all of the sites linked to its website and is not responsible for the contents of any
such linked site. The inclusion of any link does not imply endorsement by Wanij of the site. Use of any
such linked website is at the user&#39;s own risk.
         </Typography>
      </Box>

      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>7. Modifications</Typography>
      <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
      Wanij may revise these terms of service for its website at any time without notice. By using this website,
you are agreeing to be bound by the then current version of these terms of service.
         </Typography>
      </Box>

      <Box sx={{mt:'15px'}} gutterBottom>
      <Typography variant="h6" gutterBottom>8. Governing Law</Typography>
      <Typography variant='body1' gutterBottom sx={{mb:'15px'}}>
      These terms and conditions are governed by and construed in accordance with the laws of Indore,
Madhya Pradesh and you irrevocably submit to the exclusive jurisdiction of the courts in that State or
location.
         </Typography>
      </Box>
      {/* Add more sections similarly */}
    </div>
  );
};

export default TermsOfService;
