import CryptoJS from "crypto-js";

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (ciphertext, salt) => {
  if (!ciphertext) {
    console.error("No ciphertext provided for decryption");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (err) {
    console.error("Error decrypting data:", err);
    return null;
  }
};

export const decryptAPIData = async (encryptedData, keyBase64) => {
  // Parse the JSON string to get iv and ciphertext
  const { iv, ciphertext } = JSON.parse(encryptedData);

  // Convert base64 key, iv, and ciphertext to ArrayBuffer
  const key = Uint8Array.from(atob(keyBase64), (c) => c.charCodeAt(0));
  const ivBuffer = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));
  const ciphertextBuffer = Uint8Array.from(atob(ciphertext), (c) =>
    c.charCodeAt(0)
  );

  // Import the key
  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    key,
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  // Decrypt
  const decrypted = await window.crypto.subtle.decrypt(
    { name: "AES-CBC", iv: ivBuffer },
    cryptoKey,
    ciphertextBuffer
  );

  // Convert to string
  const decryptedString = new TextDecoder().decode(decrypted);

  // Try to parse as JSON, if it fails, return the string
  try {
    return JSON.parse(decryptedString);
  } catch (error) {
    console.warn("Decrypted data is not valid JSON, returning as string");
    return decryptedString;
  }
};

// Usage example
