import React from "react";
import { ClipLoader } from "react-spinners"; // Example: react-spinners
import styles from "./loder.module.css";
const LoadingIndicator = ({ lodingMessage }) => {
  return (
    <div className={styles.loader}>
      <p>
        Loading best savings rates for top {" "}
        {lodingMessage.map((item, index) => {
          if (item === "All Banks" || item === "25 Major Banks") {
            return item;
          } else {
            if (item !== null && index < lodingMessage.length - 1) {
              return item + ", ";
            } else if (item !== null && index !== 1) {
              return "and" + " " + item;
            } else {
              return item;
            }
          }
        })}
        {lodingMessage.length === 0 ? "25 Major Banks" : null}
      </p>
      <p>
        <ClipLoader size={40} color="#007bff" />
      </p>
    </div>
  );
};

export default LoadingIndicator;
