import React, { useState, useEffect, useMemo } from "react";
import BankListing from "../BankListing/BankListing";
import styles from "../SearchBar/searchbar.module.css";
import ErrorPage from "../../components/ErrorPage";
// import toast, { Toaster } from "react-hot-toast";
import numberstowords from "@rajch/numberstowords";
import LoadingIndicator from "../../components/LoadingIndicator";
import { TrackGoogleAnalyticsEvent } from "../../utils/googleAnalytics/googleAnalytics";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { decryptAPIData } from "../../utils/localEncruption";
import { useDispatch, useSelector } from "react-redux";
import { button, Input } from "@nextui-org/react";
// import { MailIcon } from "./MailIcon";
import { SearchIcon } from "../../components/icons/SearchIcon";
import {
  setAmount,
  fetchOtherData,
} from "../../redux/slice/savingsFullRatesSlice";

function SearchBar() {
  // const [bankData, setBankData] = useState([]);
  const bankData = useSelector((state) => state.savingsFullRate.bankData);
  const isLoading = useSelector((state) => state.savingsFullRate.isLoading);

  // const [Amount, setSearchInput] = useState(1000000);
  const dispatch = useDispatch();
  const Amount = useSelector((state) => state.savingsFullRate.Amount);
  // const [searchAmount, setSearchInput] = useSearchParams({
  //   Amount: reduxAmount || 1000000,
  // });
  // const Amount = searchAmount.get("Amount");
  const [formattedAmount, setFormattedAmount] = useState(
    Number(Amount).toLocaleString("en-IN")
  );
  const [error1, setError1] = useState(false);
  const [zeroError, setZeroError] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errPage, setErrPage] = useState(false);
  const [isClicked, setIsClicked] = useState(true);
  // Function that handles the search bar input change event
  const [showBankList, setShowBankList] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // const BASE_URL = `${process.env.REACT_APP_SAVINGS_RATES_URL}` + Amount;
  //
  // const fetchUsingAsyncAwaitWithFetchApi = async () => {
  //   try {
  //     const response = await fetch(BASE_URL, { mode: "cors" });
  //     const data = await response.text();
  //     const decryptedData = await decryptAPIData(
  //       data,
  //       "gsDFQwTwuuOXWgh0JG/En4NtZWbahbacdQtlCkMg9tE="
  //     );
  //
  //     setBankData(decryptedData);
  //     setLoader(false);
  //     // setFormattedAmount("");
  //     // sdfdkjl
  //   } catch (error) {
  //     setErrPage(true);
  //   }
  // };

  const handleSubmit = (event) => {
    // setLoader(true);
    setShowBankList(true);

    event.preventDefault();
    // Trigger fetching method on component mount
    dispatch(fetchOtherData());

    TrackGoogleAnalyticsEvent("Search", " Amount Search Submit", "user search");
  };
  ////
  useEffect(() => {
    // setLoader(true);
    setShowBankList(true);
    // Trigger fetching method on component mount or initial renderinguseSearchParams
    dispatch(fetchOtherData());
  }, []);

  // handleInput validation
  function handleInput(e) {
    //
    let value = e.target.value.replace(/,/g, "");
    // setSearchInput(Number(e.target.value));

    if (/^\d+$/.test(value)) {
      //
      setIsClicked(false);
      setError2(false);
      setError3(false);
      setZeroError(false); // Set error for zero value

      if (Number(value) === 0) {
        // Handle zero value
        setFormattedAmount(value); // Keep the value as is
        setZeroError(true); // Set error for zero value
        setIsClicked(true); // Highlight the error
      } else if (value > 100000000) {
        // error 1
        if (value < 100000000000) {
          setFormattedAmount(Number(value).toLocaleString("en-IN")); // Format with commas
        }
        setIsClicked(true);
        setError1(true);
      } else {
        setFormattedAmount(Number(value).toLocaleString("en-IN")); // Format with commas

        // setSearchInput(
        //   (params) => {
        //     params.set("Amount", Number(reduxAmount));
        //     return params;
        //   },
        //   { replace: true }
        // );
        // Update unformatted number state
        dispatch(setAmount(Number(value)));
        setError1(false);
        setIsClicked(false);
      }
    } else if (value.toString().indexOf(".") !== -1) {
      setFormattedAmount(e.target.value.toString());
      setError2(true);
      setIsClicked(true);
    } else {
      setFormattedAmount(e.target.value.toString());
      setError3(true);
      setIsClicked(true);
    }
  }
  // toaster  message for successful conversion

  if (errPage) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className={styles.searchContainer}>
        <div className={styles.wrapper}>
          <div className={styles.wrapperSearch}>
            {/* <div className={styles.searchBar}> */}
            {/* <Typography variant="subtitle1" gutterBottom align="center">
              {!isMobile
                ? "Enter an amount in the search bar to update the table data."
                : "Enter an amount to update the table."}
            </Typography> */}
            <form onSubmit={handleSubmit} className={styles.searchBar}>
              {/* <input
                className={styles.searchQueryInput}
                type="text"
                placeholder="Enter Savings Amount"
                value={formattedAmount}
                onChange={handleInput}
              /> */}
              <Input
                // className={styles.searchQueryInput}
                // label="Email"
                classNames={{
                  // input: styles.searchQueryInput,
                  inputWrapper: styles.searchQueryInput,
                  input: [styles.input],
                }}
                placeholder="Savings Amount"
                labelPlacement="outside"
                startContent={
                  <button onClick={handleSubmit}>
                    <SearchIcon
                      className={`flex-shrink-0 ${styles.searchIcon}`}
                    />
                  </button>
                }
                value={formattedAmount}
                onChange={handleInput}
                endContent={
                  <div
                    className={`pointer-events-none flex items-right ${styles.endContent}`}
                  >
                    <span className="text-default-400 text-small ml-2">
                      Enter an amount
                    </span>
                  </div>
                }
              />
              {/* <button
                className={styles.searchQuerySubmit}
                type="submit"
                disabled={isClicked}
              >
                <svg
                  style={{
                    width: "24px",
                    height: "24px",
                    viewBox: "0 0 24 24",
                  }}
                >
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button> */}
            </form>
            {zeroError ? (
              <span className={styles.errorMsg}>
                *Please enter amount greater than zero
              </span>
            ) : error1 ? (
              <span className={styles.errorMsg}>
                *Please enter amount less than 100000000
              </span>
            ) : error2 ? (
              <span className={styles.errorMsg}>
                *Please enter whole numbers only
              </span>
            ) : error3 ? (
              <span className={styles.errorMsg}>
                *Please enter a valid number.
              </span>
            ) : (
              <p className={styles.numberInWords}>
                {Amount !== 0 || ""
                  ? numberstowords
                      .toIndianWords(Amount, { useCase: "proper" })
                      .replace("Lakh", "L")
                      .replace("Crore", "Cr")
                      .replace("Thousand", "K")
                  : null}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* {showBankList ? <BankListing /> : isLoading ? "dfdsf" : null} */}
    </>
  );
}

export default SearchBar;
