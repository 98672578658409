import React, { useRef, useEffect } from "react";
import styles from "./filters.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import {
  toggleFilter,
  resetFilters,
  setSelectedBanks,
} from "../../redux/slice/savingsFullRatesSlice.js";
import { useDispatch, useSelector } from "react-redux";

function Filters({
  filters,
  // majorHandler,
  allHandler,
  isAllChecked,
  isMajorChecked,
  clearHandler,
  majorLength,
  BanksCounts,
  filteredItems,
  selectedBanks,
  setSelectedBanks,
  searchedBanks,
  sortedItems,
}) {
  const selectedFilters = useSelector(
    (state) => state.savingsFullRate.selectedFilters
  );
  const dispatch = useDispatch();
  // const isFirstRender = useRef(true);

  const handleChange = (event, newValue) => {
    dispatch(setSelectedBanks(newValue));
  };
  const handleFilterButtonClick = (selectedCategory) => {
    dispatch(toggleFilter(selectedCategory));
  };

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return;
  //   }

  //   if (searchedBanks.length === 0) {
  //     dispatch(setSelectedBanks([]));
  //   }
  // }, [searchedBanks.length]);

  return (
    <div className={styles.authorsWrap1}>
      <ul className={styles.filters1}>
        {/* <li>
          <span>Select Category: </span>
          <select id="select">
            <option id="male" value="male">
              All
            </option>
            <option id="female" value="female">
              Major Banks
            </option>
            <option id="all" value="all">
              all
            </option>
          </select>
        </li> */}
        <li>
          <span className={styles.filterHeading1}>
            {/* <img
              width="16"
              height="16"
              src="../bankLogos/filter2.png"
              alt="filterlogo"
            />{" "} */}
            Bank Type{" "}
          </span>
          <fieldset>
            <div>
              <input
                className={styles.radioInput}
                type="radio"
                name="acs"
                // defaultChecked="true"
                value="Germany"
                id="Major"
                checked={isMajorChecked}
                onChange={allHandler}
              />
              <label htmlFor="Major" style={{ cursor: "pointer" }}>
                Major 25 Banks
              </label>
            </div>
            <div>
              <input
                className={styles.radioInput}
                type="radio"
                name="acs"
                checked={isAllChecked}
                id="All"
                onChange={allHandler}
              />
              {/*{BanksLength} */}
              <label htmlFor="All" style={{ cursor: "pointer" }}>
                All Banks{" "}
                <small className={styles.banksCount}>
                  ({BanksCounts["All Banks"]})
                </small>
              </label>
              <span className={styles.clear1} onClick={clearHandler}>
                clear
              </span>
            </div>
            {filters.map((category, idx) => (
              <div className={styles.filtersDiv1}>
                <input
                  type="checkbox"
                  className={styles.customCheckbox}
                  // name="acs"
                  // value="Germany"
                  checked={selectedFilters.includes(category.filterName)}
                  id={idx}
                  onChange={() => handleFilterButtonClick(category.filterName)} // only call method by using arrow syntax else it is called at every render and not on click event
                  key={`filters ${idx}`}
                />
                <label htmlFor={idx} className={styles.filterLabel1}>
                  {category.filterName}{" "}
                  <small className={styles.banksCount}>
                    {" "}
                    ({BanksCounts[`${category.filterName}`]})
                  </small>
                </label>
              </div>
            ))}
          </fieldset>
        </li>
        {/* <li>
          <Autocomplete
            size="small"
            className={styles.bankSearch}
            limitTags={2}
            multiple
            id="bank-search"
            options={sortedItems}
            getOptionLabel={(option) => option.name}
            value={selectedBanks}
            onChange={handleChange}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.bref
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.acronym1
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.acronym2
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase())
                // Add more properties as needed
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Search Banks"
                placeholder={
                  selectedBanks.length === 0 ? "Start typing..." : ""
                }
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={option.name}
                  {...getTagProps({ index })}
                  key={option.id}
                />
              ))
            }
          />
        </li> */}
      </ul>
      {/* <ul id="authors" className="authors-list"></ul> */}
    </div>
  );
}

export default Filters;
