import React from "react";
import styles from "./errorpage.module.css";
// import LoadingIndicator from "../";
// import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <section className={styles.page404}>
      <div className="container">
        <div className={styles.row}>
          <div className="col - sm - 12">
            <div className="col-sm-10 col-sm-offset-1  text - center">
              <div className={styles.fourzerofourbg}>
                <h1 className="text - center">404</h1>
              </div>

              <div className={styles.contantbox404}>
                <h3 className="h2">Look like you're lost</h3>

                <p>the page you are looking for is not avaible!</p>
                <p>Try to refresh the page!</p>

                {/* <Link href="" className={styles.link_404}>
                Go to Home
              </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ErrorPage;
